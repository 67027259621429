@use "../../variables/variables.scss" as *;
.service-card:hover {
  background-image: none;
}

.service-card:hover .card-icon,
.service-card:active .card-icon {
  color: $primary-color;
}
.service-card:hover {
  color: $primary-color;
}
.card-icon:hover {
  color: $primary-color;
}
.section-main-heading {
  color: $secondary-color;
}
.service-button {
  transition: all 0.2s ease-in-out;
}
.line{
 border-color: $primary-color;
}

.service-button:hover {
  border-color: $secondary-color;
  color: $primary-color;
  transform: scale(1.1);
}
.service-card:hover .service-button,
.service-card:active .service-button {
  border-color: $secondary-color;
  color: $secondary-color;
}
#services.visible {
  animation: fadeIn 1.5s ease-in-out;
}
.card-item{
  background-color: $primary-color;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}