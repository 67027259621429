@use "../../variables/variables.scss" as *;
.fade-in {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .loaded {
    opacity: 1;
  }
  .paragraph {
    display: block;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em;
  }
  .showMoreButton{
    color: $secondary-color;
    border-color:$secondary-color;
  }
  .noNews{
    color: $secondary-color;
  }