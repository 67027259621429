@use "../../variables/variables.scss" as *;
.video-header {
  color: $secondary-color;
}
.video-container{
height: 450px;
}
.section-main-heading{
  color: $secondary-color;
}
.colored-commites{
  color: $secondary-color;
}
@media (min-width: 1024px ) and (max-width:1280px){
  .video-sec-container{
    display: flex;
    flex-direction: column;
  }
  .video{
    width: 100%;
    padding-top: 20px;
    
  }
  .video-text{
    width: 100%;
    padding: 0 10px;
  }

}
#video.visible {
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}