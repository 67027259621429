@use "../../variables/variables.scss" as *;

.sponser-component{
  background-color: $primary-color;
}


#partners.visible {
    animation: fadeIn 1.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }