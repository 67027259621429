@use "../../variables/variables.scss" as *;
.paragraph {
  display: block;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em;
}
.more-news {
  border-color: $secondary-color;
}
.more-details {
  color: $secondary-color;
}
.more-details:hover {
  text-decoration: underline;
}
#news.visible {
  animation: fadeIn 1.5s ease-in-out;
}
.news-component{
  background-color: $primary-color;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
