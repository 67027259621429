@use "../../variables/variables.scss" as *;
.video-header {
  color: $secondary-color;
}
.section-main-heading {
  color: $secondary-color;
}
.video-card{
  background-color: $primary-color;
}
.videoIcon{
  height:450px;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .video-sec-container {
    display: flex;
    flex-direction: column;
  }
  .video {
    width: 100%;
    padding-top: 20px;
  }
  .video-text {
    width: 100%;
    padding: 0 10px;
  }
}
#video.visible {
  animation: fadeIn 1.5s ease-in-out;
}
@media (max-width:796px){
  .videoIcon{
      height: 300px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
