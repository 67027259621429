@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');


body{
  font-family: 'Cairo', sans-serif;
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}