@use "../../variables/variables.scss" as *;

.register-button {
    background-color: $primary-color;
    color: white;
}
.required::after{
content: '*';
color: red;
margin-right: 5px;
}