@use "../../variables/variables.scss" as *;
.background-image {
  width: 100%;
  height: 90vh;
}
.thumbs-wrapper {
  display: none;
}
.slider-wrapper {
  border-bottom: none;
}
.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.carousel-arrow-next {
  right: 50px;
  background-color: rgba(128, 128, 128, 0.3);
}

.carousel-arrow-next:hover {
  background-color: rgba(128, 128, 128, 0.6);
}
.carousel-arrow-prev {
  left: 50px;
  background-color: rgba(128, 128, 128, 0.3);
}
.carousel-arrow-prev:hover {
  background-color: rgba(128, 128, 128, 0.6);
}
.carousel-arrow {
  font-size: 2.5rem;
  color: $primary-color;
}
.carousel-arrow .arrow-icon {
  color: white;
}
.carousel .slide img {
  width: 100%;
  height: 1100px;
  object-fit: cover;
}
.dot {
  background-color: red;
}
.slider {
  :global(.carousel .control-dots .dot) {
    background-color: blue;
  }
}
@media (max-width: 767px) {
  .carousel .slide img {
    height: 400px;
    width: 100%;
  }
  .carousel-arrow-next {
    right: 10px;
    font-size: 20px;
  }
  .carousel-arrow-prev {
    left: 10px;
    font-size: 20px;
  }
  .carousel-arrow {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .carousel .slide img {
    height: 600px;
    width: 100%;
  }
}
.my-carousel .carousel .slide .control-dots .dot {
  background-color: $secondary-color;
}
