@use "../../variables/variables.scss" as *;

#about.visible {
  animation: fadeIn 1.5s ease-in-out;
}
.skew-part{
  background-color: $primary-color;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
