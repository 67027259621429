@use "../../variables/variables.scss" as *;
.header {
  background-color: $primary-color;
  width: 100%;
}

li:hover span {
  transform: scaleX(1)
}
.logo {
  margin: -4px 0;
  padding-left: -8px;
}
li span {
  transform-origin: right;
  transform: scaleX(0);
}
.colored {
  color: $secondary-color;
}
.underline-hover {
  top: 48px;
  height: 4px;
  background-color: $secondary-color;
}
.nav-icons {
  animation: slide-down 0.8s ease-out;
}
@media (max-width: 767px) {
  .logo{
margin: 0 ;
}
}
@keyframes slide-down {
  from {
    transform: translatey(-100%);
  }
  to {
    transform: translatex(0);
  }
}
